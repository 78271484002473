<template>
  <div>
    <div v-if="loading">
      <loader />
    </div>

    <div>
      <v-snackbar
        v-model="snackbar"
        :timeout="snackBarTimeout"
        :color="color"
        >{{ snackBarText }}</v-snackbar
      >
      <div
        style="
          border-radius: 0;
          min-height: 80vh;
          font-family: Kumbh Sans !important;
        "
        v-if="pdfData != null"
      >
        <v-sheet
          :elevation="5"
          light
          style="
            position: relative;
            width: 320mm;
            height: 400mm;
            padding-top: 40px;
          "
        >
          <table
            border="1"
            cellpadding="30"
            cellspacing="0"
            style="
              width: 80%;
              border-collapse: collapse;
              text-align: left;
              margin-left: 100px;
              border: 1px solid rgba(0, 0, 0, 0.1);
              font-size: 18px;
              padding: 30px;
            "
          >
            <tbody>
              <tr style="height: 40px">
                <td></td>
                <td style="color: white">
                  <span
                    style="
                      padding: 10px;
                      display: block;
                      width: 97%;
                      background: #5c697b;
                      border-radius: 5px 5px 5px 5px;
                    "
                  >
                    Standard Fix & Flip</span
                  >
                </td>
                <!-- <td style="background: #67aaff; color: white">Wildcat Zero</td> -->
                <td style="color: white">
                  <span
                    style="
                      padding: 10px;
                      display: block;
                      width: 97%;
                      background: #67aaff;
                      border-radius: 5px 5px 5px 5px;
                    "
                  >
                    Wildcat Zero</span
                  >
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Loan Amount:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["loan_amount"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["loan_amount"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Purchase Price:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["purchase_price"] }}
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["purchase_price"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Rehab Budget:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["rehab_budget"] }}
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["rehab_budget"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">After Repair Value (ARV):</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["arv"] }}
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["arv"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">LTV:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["ltv"] }}%
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["ltv"] }}%
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">LTC:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["ltc"] }}%
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["ltc"] }}%
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Term Due:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["loan_term_duration"] }}
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["loan_term_duration"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Interest Rate:</td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program1"]["interest_rate"] }}%
                </td>
                <td style="padding-left: 5px">
                  {{ pdfData["loan_program2"]["interest_rate"] }}%
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Loan Origination Fee:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["financing_expense"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["financing_expense"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Processing Fee:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["processing_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["processing_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Appraisal Fee:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["appraisal_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["appraisal_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Credit Report Fee:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["credit_report_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["credit_report_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Attorney Document Prep Fee:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["attorney_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["attorney_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Escrow Holdback:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["escrow_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["escrow_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">6 Month Insurance Premium:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["premium_fee"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["premium_fee"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Monthly Payment:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["interest_monthly_amount"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["interest_monthly_amount"] }}
                </td>
              </tr>
              <tr style="height: 40px">
                <td style="padding-left: 5px">Down Payment:</td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program1"]["down_payment"] }}
                </td>
                <td style="padding-left: 5px">
                  ${{ pdfData["loan_program2"]["down_payment"] }}
                </td>
              </tr>
            </tbody>
          </table>
        </v-sheet>
      </div>
    </div>
  </div>
</template>
   
 <script>
import Loader from "./Loader.vue";
export default {
  name: "losDownLoad",
  components: {
    Loader,
  },
  props: ["id"],
  data() {
    return {
      loading: false,

      search: "",
      pdfData: null,
    };
  },

  mounted() {
    this.pdfData = window.losObject ? window.losObject : {};
  },
};
</script>
 