<template>
  <div class="pa-10" style="margin-left: 56px">
    <div v-if="loading">
      <Loader :loadingText="'Dashboard is loading and analyzing data...'" />
    </div>

    <div class="">
      <div>
        <v-row>
          <!-- Loan Portfolio -->
          <v-col sm="12" lg="6">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Loan Portfolio
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Overview of loans in Mortgage Automator (MA).
                    </v-list-item-subtitle>

                    <div class="d-flex justify-space-between mt-4">
                      <div
                        class="metric-card grey darken-2 pa-4 rounded-lg mr-4"
                      >
                        <div class="subtitle-1 mb-2">In Progress Loans</div>
                        <div
                          class="display-1 font-weight-bold light-blue--text"
                        >
                          {{ inProgressLoans.toLocaleString("en-US") }}
                        </div>
                      </div>
                      <div class="metric-card grey darken-2 pa-4 rounded-lg">
                        <div class="subtitle-1 mb-2">Funded Loans</div>
                        <div
                          class="display-1 font-weight-bold green--text text--accent-2"
                        >
                          {{ fundedLoansCount.toLocaleString("en-US") }}
                        </div>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>

          <!-- Contact Rolodex -->
          <v-col sm="12" lg="6">
            <v-card class="mx-auto" color="grey darken-3">
              <div class="d-flex align-start justify-center">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-1 font-weight-bold">
                      Contact Rolodex
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Overview of contacts across Crebrid.
                    </v-list-item-subtitle>

                    <div class="d-flex justify-space-between mt-4">
                      <div class="metric-card grey darken-2 pa-4 rounded-lg">
                        <div class="subtitle-1 mb-2">MA Contacts</div>
                        <div
                          class="display-1 font-weight-bold green--text text--accent-2"
                        >
                          {{ totalHot.toLocaleString("en-US") }}
                        </div>
                      </div>

                      <div
                        class="metric-card grey darken-2 pa-4 rounded-lg mx-4"
                      >
                        <div class="subtitle-1 mb-2">HubSpot Contacts</div>
                        <div
                          class="display-1 font-weight-bold orange--text text--accent-4"
                        >
                          {{ totalWarm.toLocaleString("en-US") }}
                        </div>
                      </div>

                      <div class="metric-card grey darken-2 pa-4 rounded-lg">
                        <div class="subtitle-1 mb-2">ATTOM Contacts</div>
                        <div
                          class="display-1 font-weight-bold light-blue--text"
                        >
                          {{ totalCold.toLocaleString("en-US") }}
                        </div>
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <!-- Automation -->
        <v-row>
          <v-col lg="12">
            <v-card color="grey darken-3" style="height: 100%">
              <v-tabs
                v-model="automationTab"
                color="green accent-2"
                slider-color="green accent-2"
                @change="onTabChange"
              >
                <v-tab>Overview</v-tab>
                <v-tab>In Progress</v-tab>
                <v-tab>Schedule</v-tab>
              </v-tabs>

              <v-tabs-items v-model="automationTab">
                <v-tab-item>
                  <div class="pa-4">
                    <Timeline :isMainPage="false" :logCount="10" />
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="pa-4">
                    <p class="text-h5 mb-1 font-weight-bold">
                      List of MA Loans Being Updated
                    </p>
                    <MonacoEditor
                      v-model="this.mortgageLoansIds"
                      width="100%"
                      height="400"
                      theme="vs-dark"
                      language="json"
                      :options="options"
                    ></MonacoEditor>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="d-flex align-start justify-center">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-h5 mb-1 font-weight-bold"
                        >
                          Automation Schedule
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Automation activity calendar.
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <div class="pa-4">
                    <Calendar viewtype="week" />
                  </div>
                </v-tab-item>

                <v-tab-item>
                  <div class="d-flex align-start justify-center">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-h5 mb-1 font-weight-bold"
                        >
                          Automation Schedule
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Automation activity calendar.
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>

                  <div class="pa-4">
                    <Calendar viewtype="month" />
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>

        <!-- MA & APP -->
        <v-row>
          <v-col lg="12">
            <v-card class="mx-auto" color="grey darken-3" width="100%">
              <v-tabs
                v-model="tab"
                color="green accent-2"
                slider-color="green accent-2"
              >
                <v-tab>APP</v-tab>
                <v-tab>MA</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item class="pa-4">
                  <!-- Crebrid App Content -->
                  <v-sheet class="pa-4" color="grey darken-3" data-app>
                    <v-row class="align-center justify-center">
                      <v-col>
                        <v-text-field
                          class="pa-2 my-custom-search"
                          v-model="search"
                          label="Search Loan Applications..."
                          flat
                          solo-inverted
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="loanHeaders"
                      :items="los"
                      :items-per-page="10"
                      item-key="id"
                      :search="search"
                      sort-by="createdAt"
                      :sort-desc="true"
                      class="elevation-1"
                    >
                      <template v-slot:item.createdAt="{ item }">
                        <div v-if="item.createdAt">
                          {{
                            item.createdAt
                              .toDate()
                              .toLocaleString("en-US", {
                                year: "2-digit",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                              .replace(/\//g, "-")
                          }}
                        </div>
                      </template>
                      <template v-slot:item.loanStatus="{ item }">
                        <div v-if="item.loanStatus">TBD</div>
                      </template>
                      <template v-slot:item.isProcessApplication="{ item }">
                        <div>
                          {{
                            item.isProcessApplication
                              ? item.isProcessApplication
                              : false
                          }}
                        </div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link :to="`/los/loans/${item.id}`">
                          <v-btn x-small outlined color="green accent-2">
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>

                <v-tab-item class="pa-4">
                  <!-- MA Content -->
                  <v-sheet class="pa-4" color="grey darken-3" data-app>
                    <v-row class="align-center justify-center">
                      <v-col>
                        <v-text-field
                          class="pa-2 my-custom-search"
                          v-model="search"
                          label="Search Loan Applications..."
                          flat
                          solo-inverted
                          hide-details
                          clearable
                          clear-icon="mdi-close-circle-outline"
                        ></v-text-field>
                      </v-col>
                      <v-col align="end">
                        <v-btn small outlined @click="refreshData">
                          Refresh Application
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <div>
                    <v-data-table
                      :headers="losHeaders"
                      :items="loan"
                      :items-per-page="10"
                      item-key="email"
                      :search="search"
                      sort-by="creation_date"
                      :sort-desc="true"
                      class="elevation-1"
                    >
                      <template v-slot:item.creation_date="{ item }">
                        <div v-if="item.creation_date">
                          {{
                            new Date(item.creation_date * 1000).toLocaleString(
                              "en-US",
                              {
                                year: "2-digit",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )
                          }}
                        </div>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <div>{{ getStatusLabel(item.status) }}</div>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <router-link
                          :to="`/los/loans/${item.id}/${item.loan_id}`"
                        >
                          <v-btn x-small outlined color="green accent-2">
                            View Details
                          </v-btn>
                        </router-link>
                      </template>
                    </v-data-table>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { firebaseDB } from "../../auth/firebase-auth";
import Loader from "../../components/Loader.vue";
import Calendar from "../../components/dashboard/Calendar.vue";
import Timeline from "../automations/timeline.vue";
import MonacoEditor from "monaco-editor-vue";

export default {
  components: {
    Loader,
    Calendar,
    Timeline,
    MonacoEditor,
  },
  props: ["id"],
  data() {
    return {
      loading: true,
      jobEvent: [],
      color: "success",
      snackbar: false,
      snackBarText: "",
      snackBarTimeout: 2000,
      page: 1,
      limit: 10,
      totalRecords: 0,
      tab: "",
      loan: [],
      los: [],
      fundedLoansCount: 0,
      inProgressLoans: 0,
      totalHot: 0,
      totalWarm: 0,
      totalCold: 0,
      losLogObject: null,
      dialog: false,
      apiKey: "AIzaSyBkvot_wof6iT87tGnaYMA9ZFIFRvZOK5o",
      mortgageLoansIds: [],
      losHeaders: [
        {
          text: "Loan Title",
          align: "start",
          value: "loan_title",
        },
        {
          text: "Reference #",
          align: "start",
          value: "loan_id",
        },
        {
          text: "Address",
          align: "start",
          value: "property.address.street",
        },
        {
          text: "City",
          align: "start",
          value: "property.address.city",
        },
        {
          text: "State",
          align: "start",
          value: "property.address.prov",
        },
        {
          text: "Created At",
          align: "start",
          value: "creation_date",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],
      loanHeaders: [
        {
          text: "Email",
          align: "start",
          width: "100",
          value: "email",
        },
        {
          text: "Reference #",
          align: "start",
          width: "50",
          value: "ReferenceNumber",
        },
        {
          text: "Address",
          align: "start",
          width: "100",
          value: "street",
        },
        {
          text: "City",
          align: "start",
          width: "50",
          value: "city",
        },
        {
          text: "State",
          align: "start",
          width: "50",
          value: "state",
        },
        // {
        //   text: "Zipcode",
        //   align: "start",
        //   width: "50",
        //   value: "P1005",
        // },
        {
          text: "Created At",
          align: "start",
          width: "100",
          value: "createdAt",
        },
        {
          text: "Processed",
          align: "center",
          width: "100",
          value: "isProcessApplication",
        },
        {
          text: "Actions",
          align: "center",
          width: "100",
          value: "actions",
        },
      ],
      search: "",
      automationTab: null,
    };
  },
  computed: {},
  filters: {
    formatDate: function (date) {
      return moment(date).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.fetchLosData();
    this.fetchLoanData();
    this.fetchCrmData();
    this.fetchShapesData();
    this.fetchMarketLeadsData();
    this.loading = false;
  },
  methods: {
    getStatusLabel(statusCode) {
      const statusMap = {
        0: "In Progress",
        1: "Funded",
        2: "Discharged",
        3: "Terminated",
      };
      return statusMap[statusCode] || "Unknown Status";
    },
    async onTabChange(index) {
      this.loading = true;
      if (index === 1) {
        const loanIdsData = await firebaseDB
          .firestore()
          .collection("loanUpdateQueue")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        if (!loanIdsData.empty) {
          const loanData = loanIdsData.docs.map((doc) => doc.data());
          this.mortgageLoansIds = JSON.stringify(
            loanData.length ? loanData[0].mortgageLoansIds : []
          );
        }
      }
      this.loading = false;

    },
    async fetchLosData() {
      this.loading = true;
      const losRef = firebaseDB.firestore().collection("mortgageLoans");
      try {
        const { docs } = await losRef
          .orderBy("creation_date", "desc")
          .limit(50)
          .get();
        const inProgressQuery = await losRef.where("status", "==", 0).get(); // Filter by in-progress status
        const fundedQuery = await losRef.where("status", "==", 1).get(); // Filter by funded status
        this.loan = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.inProgressLoans = inProgressQuery.docs.length;
        this.fundedLoansCount = fundedQuery.docs.length;
        const loanIdsData = await firebaseDB
          .firestore()
          .collection("loanUpdateQueue")
          .orderBy("createdAt", "desc")
          .limit(1)
          .get();

        if (!loanIdsData.empty) {
          const loanData = loanIdsData.docs.map((doc) => doc.data());
          this.mortgageLoansIds = JSON.stringify(
            loanData.length ? loanData[0].mortgageLoansIds : []
          );
        }
      } catch (error) {
        console.log("Error in fetching loan data:\n" + error);
      }
    },

    async fetchLoanData() {
      this.loading = true;
      const loansRef = firebaseDB.firestore().collection("loans");
      try {
        const { docs } = await loansRef
          .where("source", "==", "app")
          .orderBy("createdAt", "desc")
          .limit(50)
          .get();
        this.los = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },

    async refreshData() {
      this.loading = true;
      try {
        const url =
          location.hostname === "localhost"
            ? `http://localhost:5000`
            : `https://hermosa-server-dot-crebrid-os.uc.r.appspot.com`;

        const headers = {
          "Content-Type": "application/json",
        };

        await axios.post(`${url}/api/v1/pdf/mortgage-loans`, { headers });
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },

    async fetchCrmData() {
      this.loading = true;
      const crmRef = firebaseDB.firestore().collection("contacts");
      try {
        const { docs } = await crmRef.get();

        this.crm = docs.map((doc) => {
          const { id } = doc;
          const data = doc.data();
          return { id, ...data };
        });
        this.totalHot = this.crm.length;
      } catch (error) {
        console.log("Error in fetching los data:\n" + error);
      }
    },

    async fetchShapesData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";
        await axios
          .get(
            `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/shapes?page=${this.page}&limit=${this.limit}&key=${key}`
          )
          .then((response) => {
            this.totalWarm = response.data.TotalRecords;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.log("Error in fetching Shapes data:\n" + error);
      }
    },

    async fetchMarketLeadsData() {
      this.loading = true;

      try {
        const key = "21b77cec7cb516c40d9d7216ae74e3c3";

        let apiURL = `https://crebrid-flask-dot-crebrid-os.uc.r.appspot.com/leads?page=${this.page}&limit=${this.limit}&totalRecords=${this.totalLeads}&key=${key}`;

        await axios
          .get(apiURL)
          .then((response) => {
            this.totalCold = response.data.flips[0].TotalRecords;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (error) {
        console.log("Error in fetching Leads data:\n" + error);
      }
    },

    moment: function () {
      return moment();
    },
  },
};
</script>

<style lang="scss" scoped>
.os-container {
  min-height: calc(100vh - 120px);
}
#mapdiv {
  min-height: 500px;
  width: 100%;
}

.metric-card {
  flex: 1;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}
</style>
